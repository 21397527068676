import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "menuItem" }

import { computed, type ComputedRef } from 'vue';

import {
  SMenu, SMenuItem, SSidebar,
} from '@simmons/components';
import useUserAccess from '@/composables/useUserAccess';
import useSelectedProduct from '@/composables/useSelectedProduct';
import { useStore } from '@/store';

import { APP__DARK_MODE } from '@/store/modules/app/getters';

import { routePagesInMenu, menuRouteLabels } from '@/config/constants';
import {
  isInArray,
  filterRoutesByProductName,
  getViewFromRoute,
  getMetaValueFromRoute,
} from '@/utils';

import type { ActiveProduct } from '@/config';
import type { ElementOf } from '@/types/utilityTypes';
import type { RouteRecordComposite } from '@/types/vueRouter';

type MenuLink = {
  routeName: string;
  key: string;
  icon: string | unknown;
  label: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'AppSidebar',
  props: {
  sidebarWidth: {
    type: String,
    required: false,
    default: '250px',
  },
},
  setup(__props) {



const { useGetter } = useStore();
const { selectedProduct } = useSelectedProduct();

const darkMode = computed((): boolean => useGetter(APP__DARK_MODE));

const mapMenuLink = (routeRecord: RouteRecordComposite): MenuLink => ({
  routeName: routeRecord.name as string,
  key: routeRecord.name as string,
  icon: routeRecord.meta?.icon,
  label: menuRouteLabels[getViewFromRoute(routeRecord) as ElementOf<typeof routePagesInMenu>],
});

function getMenuLinks(currentProduct: ComputedRef<ActiveProduct>): MenuLink[] {
  const { routesUserCanAccess } = useUserAccess(currentProduct);
  const validMenuRoutes = routesUserCanAccess.filter((routeRecord) => {
    const routeViewName = getViewFromRoute(routeRecord);
    const isHidden = getMetaValueFromRoute(routeRecord, 'hidden') as boolean | undefined;

    return (!isHidden && routeViewName && isInArray(routeViewName, routePagesInMenu));
  });
  const validProductRoutes: RouteRecordComposite[] = filterRoutesByProductName(
    currentProduct.value,
  )(validMenuRoutes);

  return validProductRoutes.map(mapMenuLink);
}

const menuLinks = computed(() => (selectedProduct.value
  ? getMenuLinks(selectedProduct as ComputedRef<ActiveProduct>)
  : []
));

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(SSidebar), {
    dark: darkMode.value,
    width: __props.sidebarWidth
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(SMenu), null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuLinks.value, (menuLink) => {
            return (_openBlock(), _createBlock(_unref(SMenuItem), {
              key: menuLink.key,
              "menu-key": menuLink.key,
              "icon-text": menuLink.label.slice(0, 2).toUpperCase(),
              active: true
            }, _createSlots({
              default: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: { name: menuLink.routeName },
                  class: _normalizeClass({ 'white-link': darkMode.value })
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_1, _toDisplayString(menuLink.label), 1)
                  ]),
                  _: 2
                }, 1032, ["to", "class"])
              ]),
              _: 2
            }, [
              (menuLink.icon)
                ? {
                    name: "icon",
                    fn: _withCtx(() => [
                      _createVNode(_component_router_link, {
                        to: { name: menuLink.routeName },
                        class: _normalizeClass({ 'white-link': darkMode.value })
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(menuLink.icon)))
                        ]),
                        _: 2
                      }, 1032, ["to", "class"])
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["menu-key", "icon-text"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["dark", "width"]))
}
}

})