import type { ValueOf } from 'ts-essentials';

import { isActiveProduct, removePortalSuffix } from '@/utils/convertPortalProduct';
import { getActiveProducts, getActiveImportProducts } from '@/utils/envVars';
import { ActiveProducts, PlatformProducts, PortalProducts } from './constants';

export type PlatformProduct = ValueOf<typeof PlatformProducts>;
export type PortalProduct = ValueOf<typeof PortalProducts>;
export type ActiveProduct = ValueOf<typeof ActiveProducts>;
export type InactiveProduct = Exclude<PortalProduct, ActiveProduct>;

export function getValidActiveProducts(): ActiveProduct[] {
  const envActiveProducts = getActiveProducts();

  return envActiveProducts.filter(isActiveProduct);
}

export function getProductPermissions(product: PortalProduct) {
  const productWithoutSuffix = removePortalSuffix(product);

  return {
    ownerPermissions: <const>[`${productWithoutSuffix}-collaboration-owner`] satisfies readonly string[],
    collaboratorPermissions: <const>[`${productWithoutSuffix}-collaboration`] satisfies readonly string[],
  };
}

export function getValidActiveImportProducts(): ActiveProduct[] {
  const envActiveImportProducts = getActiveImportProducts();

  return envActiveImportProducts.filter(isActiveProduct);
}

export default getValidActiveProducts;
