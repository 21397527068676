import type { RouteRecordComposite } from '@/types/vueRouter';

function getMetaValueFromRoute(route: RouteRecordComposite, key: string): unknown | undefined {
  const { meta } = route;
  if (!meta) {
    return undefined;
  }

  if (!Object.keys(meta).includes(key)) {
    return undefined;
  }

  return meta[key];
}

export default getMetaValueFromRoute;
